<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-4">
          <v-row>
            <v-col lg="7" class="info d-none d-md-flex align-center justify-center">
              <div class="d-none d-sm-block">
                <div class="d-flex align-center pa-10">
                  <div>
                    <h2 class="display-1 white--text font-weight-medium">اكاديمية البشير لتكنولوجيا المعلومات والذكاء الاصطناعي</h2>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col lg="5">
              <div class="pa-7 pa-sm-12">
                <img src="@/assets/alBashir_logo.svg" height="150px" width="150px"/>
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">تسجيل الدخول</h2>
                <v-form ref="form">
                  <v-text-field v-model="inputs.email" @keyup.enter="login" :rules="emailRules" label="الهاتف" class="mt-4"
                    required outlined>
                  </v-text-field>
                  <v-text-field v-model="inputs.password" @keyup.enter="login" :counter="10"
                    :type="isPasswordVisible ? 'text' : 'password'" :rules="passwordRules" label="الرمز" required
                    outlined :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                    hide-details @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>
                  <v-btn color="info" block class="mr-4 mt-5" :loading="this.$store.state.btnLoginLoading" @click="login">تسجيل الدخول</v-btn>
                </v-form>
                <div class="text-center mt-4">
                  <v-alert v-if="this.$store.state.errorMessageShow" type="error" color="#ff416c"> هنالك خطأ في الايميل او
                    الباسورد </v-alert>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  name: "BoxedLogin",

  data: () => ({

    inputs: {
      email: null,
      password: null,
    },

    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },

    userInfo: {},

    isPasswordVisible: false,
    valid: true,
    password: "",
    passwordRules: [
      v => !!v || "الرمز مطلوب",
      v => (v && v.length <= 5) || "الرمز ست احرف او ارقام على الاقل"
    ],
    email: "",
    emailRules: [
      v => !!v || "الهاتف مطلوب",
    ],
    checkbox: false
  }),
  computed: {},

  methods: {
    async login() {

      this.$store.dispatch('changeLoginBtnToTrue')

      await this.getUserInfo()

      this.$store.dispatch('submitLogin', {
        email: this.inputs.email,
        password: this.inputs.password,
        userInfo: this.userInfo,
      })
    },

    async getUserInfo() {
      this.axios
        .get('http://ip-api.com/json')
        .then(Response => {
          this.userInfo = Response.data
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  }
};
</script>
